@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.secondaryHeaderOuter {
	padding: 16px 24px;
	background-color: $white;
	width: 100%;
	min-width: $max-app-width;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	.secondaryHeaderInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			font-weight: $font-bold;
			font-size: 18px;
			line-height: 22px;
			color: $page-title;
		}
	}
}

.searchBar {
	position: relative;
	width: 388px;
	background-color: $white;
	margin-left: auto;
	border-radius: 7px;
	.searchIcon {
		position: absolute;
		left: 349px;
		top: 7px;
		path {
			fill: $gray-path;
		}
	}
	.searchButton {
		background-color: white;
	}
	.search {
		width: 100%;
		height: 32px;
		line-height: 32px;
		input {
			margin-bottom: 0;
			height: 32px;
			line-height: 54px;
			font-size: 14px;
			text-align: left;
			font-weight: $font-semi-bold;
			color: $input-text;
			margin-top: -3px;
			padding-left: 15px;
			border-radius: 3px;
			border: 1px solid $search-input;
			box-shadow: none;
			&:focus {
				border-color: $Blue400;
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
				outline: none;
			}
		}
	}
}

.bodyOuter {
	padding: 24px;
	width: 100%;
	min-width: $max-app-width;
	position: relative;
	z-index: 0;
	.bodyInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		.applyButton {
			background-color: $Blue400;
			color: $white;
			margin-left: auto;
			text-transform: uppercase;
			&:hover {
				background-color: $btn-primary-hover;
			}
		}
		.whiteButton {
			background-color: $white;
			color: $white-button-text;
			text-transform: uppercase;
			border: 1px solid $White105;
			box-sizing: border-box;
			border-radius: 4px;
			font-size: 12px;
			&:hover {
				background-color: $white;
				color: $black;
			}
		}

		.filtersDiv {
			.inputDiv {
				flex-basis: 20%;
			}
			.row {
				display: flex;
				flex-direction: row;
				.buttonDiv1 {
					max-width: 10%;
					margin-right: 10px;
					width: 10%;
				}
				.clearFilters {
					display: flex;
					max-width: 10%;
					width: 10%;
					.clearFilterButtonActive {
						background-color: grey;
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 13px;
						align-items: center;
					}
					.clearFilterButtonInactive {
						background-color: $Gray800;
						font-family: Lato;
						font-style: normal;
						font-weight: bold;
						font-size: 13px;
						align-items: center;
					}
				}
				.selectedFilters {
					max-width: 90%;
					width: 90%;
					display: inline;
					.showSelectedFilters {
						border: 1px solid $Blue400;
						box-sizing: border-box;
						border-radius: 4px;
						padding: 4px;
						color: $Blue400;
						font-weight: $font-bold;
						margin-right: 15px;
						display: inline-flex;
						width: fit-content;
						margin-bottom: 8px;
						padding-left: 15px;
						padding-right: 15px;
						.closeFilter {
							margin-left: 10px;
						}
					}
				}
			}
			.horizontalStyles {
				margin: 18px;
			}

			.bulkActionRow {
				margin-bottom: 15px;
				display: flex;
				flex-direction: row;
				.bulkActions {
					margin-top: 0;
					margin-bottom: 0;
					align-items: center;
					width: 30%;
					max-width: 30%;
					font-weight: bold;
					color: black;
					font-family: Lato;
					font-style: normal;
					font-size: 14px;
				}
				.downloadAction {
					margin-top: 0;
					margin-bottom: 0;
					font-family: Lato;
					font-style: normal;
					font-weight: bold;
					font-size: 14px;
					color: $Blue400;
					align-items: center;
					max-width: 82%;
					width: 82%;
					text-align: right;
					&:hover {
						cursor: pointer;
					}
				}
				.mailAction {
					margin-top: 0;
					margin-bottom: 0;
					font-family: Lato;
					font-style: normal;
					font-weight: bold;
					font-size: 14px;
					color: $Blue400;
					align-items: center;
					display: flex;
					max-width: 18%;
					width: 18%;
					margin-left: 14px;
					&:hover {
						cursor: pointer;
					}
				}
			}

			.filterButton {
				background-color: $white;
				color: $white-button-text;
				border: 1px solid $White105;
				box-sizing: border-box;
				border-radius: 4px;
				font-size: 14px;
				font-family: Lato;
				font-style: normal;
				font-weight: bold;
				&:hover {
					background-color: $white;
					color: $black;
				}
			}

			.filterContent {
				border: 1px solid rgb(221, 221, 221);
				padding: 1em;
				border-radius: 10px;
				background-color: $white;
				box-shadow: rgba(0, 0, 0, 0.15) 0 0.5rem 1rem !important;
			}

			.badgeCustom {
				cursor: default;
				background-color: $Blue400;
				display: inline-block;
				padding: 2px 8px 3px 12px !important;
				border-radius: 40px;
				border: 1px solid $White103;
				font-family: "lato";
				font-weight: 600;
				font-size: 14px;
				margin-right: 10px;
				margin-top: 10px;
			}

			.container {
				position: absolute;
				z-index: 1;
				height: 250px;
				width: 489px;
				margin-top: 15px;
				.innerBody {
					display: flex;
					background: $white;
					border: 1px solid $gray300;
					border-radius: 4px;
					height: auto;
					position: relative;

					.leftSide {
						border-right: 1px solid $gray300;
						border-radius: 4px;

						.filters {
							cursor: pointer;
							padding: 12px 12px 12px 12px;
							&:nth-child(n + 2) {
								border-top: 1px solid $filter-input;
							}
							.filterLabel {
								display: flex;
								justify-content: space-between;
								font-weight: $font-bold;
								line-height: 17px;
							}
							.selectedNotch {
								display: flex;
								justify-content: space-between;
								font-weight: $font-bold;
								line-height: 17px;
							}
							.selectedNotch::before {
								content: "";
								width: 0;
								height: 0;
								border: 0.8em solid transparent;
								position: absolute;
								right: 290px;
								border-left: 12px solid $Blue400;
							}
							.labelStyle {
								margin-left: 20px;
								margin-top: 5px;
								margin-bottom: 5px;
							}
							.smallInput55 {
								width: 251px;
								border-right: 0;
								border-radius: 0;
							}
							.label1 {
								font-weight: $font-bold;
								letter-spacing: 1px;
								text-transform: uppercase;
								color: $label;
								font-size: 12px;
								line-height: 24px;
								color: $label-light;
								flex-basis: 30%;
								margin: 10px;
							}
							.inputStyle {
								border-radius: 3px;
								margin-top: 5px;
								margin-bottom: 15px;
							}
							.salaryInputStyle {
								font-weight: $font-bold;
								letter-spacing: 1px;
								text-transform: uppercase;
								color: $label;
								font-size: 12px;
								line-height: 24px;
								color: $label-light;
								flex-basis: 30%;
								margin: 10px;
								margin-bottom: 10px;
								margin-top: 15px;
							}
							.inputBox {
								width: 100%;
								height: 32px;
								line-height: 32px;
								input {
									margin-bottom: 0;
									height: 32px;
									line-height: 54px;
									font-size: 14px;
									text-align: left;
									font-weight: $font-semi-bold;
									color: $input-text;
									margin-top: 5px;
									padding-left: 32px;
									border-radius: 3px;
									border: 1px solid $search-input;
									box-shadow: none;
									&:focus {
										border-color: $Blue400;
										box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
										outline: none;
									}
								}
								.select-after {
									width: 80px;
								}
							}
							.unitsLabel {
								font-weight: 500;
								font-size: 12px;
								line-height: 24px;
								letter-spacing: 0.01em;
								background-color: $White104;
								border: 1px solid $White104;
								line-height: 40px;
								color: $black;
								margin-right: 0;
								margin-left: 0;
								margin-top: 0;
								padding-left: 1.5em;
								padding-right: 1.5em;
								border-left: 0;
								border-radius: 0;
							}
							.locationDiv {
								position: absolute;
								background-color: $white;
								max-width: 40%;
								width: 40%;
							}
							.filterStyle {
								position: absolute;
								padding: 1em;
								background-color: $white;
							}
						}
					}
				}

				.header {
					display: flex;
					justify-content: flex-end;
					background-color: lightgrey;
					border-radius: 4px;
					padding: 10px;
					.row {
						display: flex;
						font-weight: $font-bold;
						align-items: right;
						cursor: pointer;
						.applyText {
							background-color: $Blue400;
							color: $white;
							margin-right: 15px;
						}
						.applyTextInactive {
							background-color: $content-description;
							color: $white;
							margin-right: 15px;
						}
						.clearText {
							margin-right: 10px;
							background-color: $white;
							color: $white-button-text;
							border: 1px solid $White105;
							box-sizing: border-box;
							border-radius: 4px;
							&:hover {
								background-color: $white;
								color: $black;
							}
						}
					}
				}
			}
		}
		.tableContainer {
			background: $white;
			border: 1px solid $Gray200;
			border-radius: 4px;
		}
	}

	.actionsWrapper {
		display: flex;
		align-items: center;
		.downloadIcon {
			cursor: pointer;
			color: grey;
		}
		.quickviewIcon {
			cursor: pointer;
			color: grey;
		}
		.quickView {
			padding: 8px 9px 3px 9px;
		}
		.quickView {
			&:hover {
				background: $icon-label;
				border-radius: 50px;
			}
		}
	}
	.candidateTable {
		overflow-x: hidden;
		white-space: nowrap;
	}
	.paginationStyle {
		float: right;
		margin-top: -47px;
		margin-right: 85px;
	}
}

.filterSelected {
	background: $Blue400;
	color: $white;
}
.filterNotSelected {
	background-color: $white;
	color: black;
}
.noticeBadge {
	display: block;
	color: rgba(0, 0, 0, 0.65);
	border: 1px solid $gray-base;
	border-radius: 18px;
	padding: 5px;
	margin-left: 10px;
	width: 35%;
}
.w-160 {
	width: 190px;
	max-width: 190px;
	vertical-align: middle;
	overflow: hidden;
	text-overflow: ellipsis;
}
.modalCards {
	height: 100vh;
	padding: 12px;
	.downloadPdfIcon {
		cursor: pointer;
		margin-bottom: -4px;
	}
}

.customHeaderDownload {
	float: right;
	text-transform: uppercase;
	margin-top: -80px;
	background-color: $Blue400;
	border-radius: 6px;
	font-size: 13px;
	line-height: 33px;
	margin-right: 39px;
	&:hover {
		background-color: $Blue400;
		color: $white;
		cursor: pointer;
	}
	.customIcon {
		cursor: pointer;
		color: $white;
	}
}

.customDisableIcon {
	pointer-events: none;
	opacity: 0.5;
}
.mailIcon {
	background-color: $white;
	color: $Gray700;
	border: 1px solid $gray-base;
	font-family: Lato;
	font-style: normal;
	font-size: 13px;
	margin-left: 8px;
	&:hover {
		background-color: $white;
		color: $Gray700;
		cursor: pointer;
	}
}
.filtername {
	margin-right: 3px;
	margin-left: 3px;
}
.loader {
	font-size: 16px;
	line-height: 20px;
	color: black;
	text-transform: uppercase;
}
.imageClass {
	vertical-align: unset;
}
.warning{
	position: absolute;
    width: 16px;
    left: 29px;
	margin-top: -8px;
}
