@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.button {
	position: relative;
	font-size: 14px;
	font-weight: $font-semi-bold;
	text-align: center;
	padding: 8px 16px;
	cursor: pointer;
	user-select: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	min-width: 50px;
	border-radius: 4px;
	background-color: #2fcb53;
	color: $white;
	transition: background 0.2s ease;
	&:hover {
		background-color: #48dd84;
	}
}
.shadow {
	box-shadow: 0 8px 12px 0 rgba(233, 235, 235, 0.16),
		0 2px 8px 0 rgba(0, 0, 0, 0.08);
}
.outline {
	background-color: $white;
	border-color: #2188ff;
	color: #2188ff;
	&:hover {
		background-color: #2188ff;
		border-color: #2188ff;
		color: $white;
	}
}
.secondary {
	background-color: $white;
	border-color: rgb(186, 186, 186);
	color: #4c4c4c;
	&:hover {
		background-color: #eee;
		border-color: #eee;
	}
}
.danger {
	background-color: #eb5054;
	border-color: #eb5054;
	color: $white;
	&:hover {
		background-color: #ff575b;
		border-color: #ff575b;
	}
}
.disabled {
	background: #bababa;
	border-color: #bababa;
	color: $white;
	pointer-events: none;
	box-shadow: none;
	opacity: 0.4;
	&:hover {
		background: #bababa;
	}
}
.loading {
	display: flex;
	margin-right: 10px;
	pointer-events: none;
	.spinnerDiv {
		width: 16px;
		height: 16px;
	}
	.circle {
		stroke: $white;
	}
}
.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
}

.ripple:after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
	background-repeat: no-repeat;
	background-position: 50%;
	transform: scale(10, 10);
	opacity: 0;
	transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
	transform: scale(0, 0);
	opacity: 0.3;
	transition: 0s;
}
