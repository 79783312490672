@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
$red-base: #d72b3f;
$white: $white;
$black: #000;

.profileMenuBox {
	background-clip: padding-box;
	background-color: $white;
	border: 1px solid rgba(27, 31, 35, 0.15);
	border-radius: 4px;
	box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
	left: 0;
	list-style: none;
	margin-top: 2px;
	padding-bottom: 5px;
	padding-top: 5px;
	position: absolute;
	top: 100%;
	width: 160px;
	z-index: 100;
	left: auto;
	right: 0;
}
.content {
	display: block;
	.chevronRight {
		position: absolute;
		top: 43%;
		right: 20px;
		path {
			opacity: 0.5;
			stroke: #b2b2b2;
		}
	}
}
.menu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0;
	.separator {
		width: 100%;
		line-height: 24px;
		list-style-type: none;
		padding: 0 0 8px;
		margin-top: 10px;
		border-top: 1px solid #e1e4e8;
		border-bottom: 0;
	}
	.itemWrapper {
		width: 100%;
		position: relative;
	}
	.menuItemUser {
		width: 100%;
		text-align: left;
		padding: 4px 10px 4px 16px;
		font-size: 12px;
		line-height: 1.3;
		color: #24292e;
		font-weight: $font-regular;
	}
	.role {
		font-weight: $font-bold;
	}
	.item {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 4px 10px 4px 16px;
		font-size: 14px;
		line-height: 1.3;
		color: #24292e;
		font-weight: $font-regular;
		cursor: pointer;
		& a {
			color: unset;
		}

		.icon {
			margin-right: 12px;
			path {
				fill: #b2b2b2;
			}
		}
		&:hover {
			background-color: #0366d6;
			color: $white;

			.icon path {
				fill: $white;
			}
		}

		&.danger {
			color: $red-base;

			.icon path {
				fill: $red-base;
			}
			&:hover {
				color: $white;
				background-color: $red-base;

				.icon path {
					fill: $white;
				}
			}
		}
	}
}
