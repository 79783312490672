// Utilities
// Single purpose, immutable styles, that do one thing well.
// Only place styles class here, Not reusable variables

:global {
    @import 'animations';
    @import 'borders';
    @import 'box-shadows';
    @import 'gradients';
    @import 'layout';
    @import 'margins';
    @import 'paddings';
    @import 'scrollbars';
    @import 'typography';
}
