@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.secondaryHeaderOuter {
	padding: 16px 24px;
	background-color: $white;
	width: 100%;
	min-width: $max-app-width;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	.secondaryHeaderInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		.title {
			font-weight: $font-bold;
			font-size: 18px;
			line-height: 22px;
			color: $page-title;
			&:hover {
				color: $black;
			}
		}
		.breadCrumb {
			font-weight: 500;
			font-size: 18px;
			color: $label-breadcrumb;
			.main {
				font-weight: $font-bold;
			}
		}
	}

	.rightArea {
		display: flex;
		.searchInputBoxWrapper {
			position: relative;
			.searchInputBoxIcon {
				cursor: pointer;
				position: absolute;
				right: 12px;
				top: 5px;
				path {
					fill: $gray-path;
				}
			}
			.searchInputBox {
				input {
					width: 350px;
					height: 32px;
					line-height: 54px;
					font-size: 14px;
					font-weight: $font-semi-bold;
					color: $input-text;
					border-radius: 3px;
					border: 1px solid $search-input;
					box-shadow: none;
					margin-bottom: 0;
					&:focus {
						border-color: $Blue400;
						box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
						outline: none;
					}
				}
			}
		}
		.optionMenuWrapper {
			padding: 0 14px;
			.buttonStyle {
				padding: 0 !important;
			}
		}
	}
}

.downloadReport {
	padding: 0 !important;
	bottom: -11px;
	display: flex;
	width: max-content;
	align-items: center;
	padding: 8px 16px;
	background: $white;
	border: 1px solid $gray300;
	border-radius: 4px;
	box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.06);
	cursor: pointer;
	.submenuItem {
		&:hover {
			background: $white !important;
		}
	}
	.downloadReportIcon {
		cursor: pointer;
		padding-right: 6px;
	}
}
.subMenuItemText {
	font-family: Lato;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	color: $white-button-text;
	align-items: center;
	cursor: pointer;
}
.bodyOuter {
	width: 100%;
	min-width: $max-app-width;
	@include less-than-width(1220px) {
		width: 1220px;
		overflow-x: scroll;
	}
	.bodyInner {
		margin: 0 auto;
		.spinnerContainer {
			padding-top: 24px;
		}
		.container {
			display: flex;
			border-radius: 4px;
			.left {
				width: 280px;
				overflow-y: auto;
				background: $white;
				border-radius: 1px;
				margin-top: 1px;
				.header {
					display: flex;
					justify-content: space-between;
					padding: 18px;
					.text {
						display: flex;
						align-items: center;
						font-weight: $font-bold;
						text-transform: uppercase;
						color: $label;
					}
					.row {
						display: flex;
						font-weight: $font-bold;
						cursor: pointer;
						.applyText {
							color: $Blue400;
						}
						.applyTextInactive {
							color: $clear-text;
						}
						.clearText {
							margin-left: 10px;
							color: $red-base;
						}
						.clearTextDisabled {
							margin-left: 10px;
							color: $clear-text;
						}
					}
				}

				.filters {
					cursor: pointer;
					padding: 18px 18px 0 18px;
					&:nth-child(n + 2) {
						border-top: 1px solid $filter-input;
					}
					.filterLabel {
						display: flex;
						justify-content: space-between;
						font-weight: $font-bold;
						line-height: 17px;
						margin-bottom: 20px;
					}
					.smallInput55 {
						width: 55px;
					}
					.smallMinInput55 {
						margin-left: 5px;
					}
					.label1 {
						font-weight: $font-bold;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: $label;
						font-size: 12px;
						line-height: 24px;
						color: $label-light;
						flex-basis: 30%;
						margin: 10px;
					}
					.noticeBadge {
						display: block;
						color: rgba(0, 0, 0, 0.65);
						border: 1px solid $search-input;
						border-radius: 18px;
						padding: 5px;
						margin-left: 10px;
						width: 34%;
						margin-bottom: 10px;
					}
					.inputStyle {
						border-radius: 3px;
						margin-top: 5px;
						margin-bottom: 15px;
					}
					.salaryInputStyle {
						font-weight: $font-bold;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: $label;
						font-size: 12px;
						line-height: 24px;
						color: $label-light;
						flex-basis: 30%;
						margin: 10px;
						margin-bottom: 10px;
						margin-top: 15px;
					}
					.unitsLabel {
						font-weight: 500;
						font-size: 12px;
						line-height: 24px;
						letter-spacing: 0.01em;
						color: $label-units;
					}
					.searchInput {
						background-position: 10px left;
						background-color: $white;
						height: 42px;
						font-size: 13px;
						line-height: 16px;
						letter-spacing: 0.26px;
						color: $Gray104;
						margin-bottom: 13px;
					}
				}
			}
			.right {
				width: 1068px;
				padding: 40px 50px;
				overflow-y: auto;
				background: $white;
				margin: 40px;
				.top {
					.pageName {
						font-weight: $font-bold;
						font-size: 20px;
						line-height: 26px;
						color: $black;
					}
					.jobDescCardRow {
						display: flex;
						margin-top: 30px;
						margin-bottom: 30px;
						.jobInfoCard {
							flex-basis: 50%;
							background: $white;
							box-shadow: 0 4px 12px rgba(0, 0, 0, 0.13);
							border-radius: 4px;
							padding: 20px;

							.name {
								font-weight: $font-bold;
								font-size: 16px;
								line-height: 26px;
								color: $Blue400;
							}

							.details {
								line-height: 26px;
								margin-top: 10px;
								color: $detail-label;
							}
						}
						.appCountCard {
							flex-basis: 20%;
							margin-left: 20px;
							border: 1px solid $card-label;
							border-radius: 4px;
							padding: 20px;

							.name {
								font-weight: $font-bold;
								font-size: 16px;
								line-height: 26px;
								color: $name-label;
							}

							.number {
								font-size: 48px;
								line-height: 48px;
								margin-top: 20px;
								color: $Gray102;
								text-align: right;
							}
						}

						.bestMatchCountCard {
							flex-basis: 20%;
							margin-left: 20px;
							border: 1px solid $card-label;
							border-radius: 4px;
							padding: 20px;

							.name {
								font-weight: $font-bold;
								font-size: 16px;
								line-height: 26px;
								color: $name-label;
							}

							.number {
								font-size: 48px;
								line-height: 48px;
								margin-top: 20px;
								color: $Gray102;
								text-align: right;
							}
						}
					}
					.rowMT {
						display: flex;
						margin-top: 15px;
						justify-content: space-between;
						.learnerStatus {
							width: 250px;
							margin-bottom: 20px;
						}
						.selectButtons {
							display: inline-flex;
							.upper {
								margin-left: 10px;
								text-transform: uppercase;
								background: grey;
							}
							.upperActive {
								margin-left: 10px;
								text-transform: uppercase;
								background: $green;
							}
							.whiteButton1 {
								font-size: 14px;
							}
						}
					}
				}
				.bottom {
					margin-top: 10px;
					border: 1px solid $gray300;
					border-radius: 4px;
				}
			}
		}
	}
}
.actionsWrapper {
	display: flex;
	align-items: center;
	.downloadIcon {
		cursor: pointer;
		color: grey;
	}
	.quickviewIcon {
		cursor: pointer;
	}
	.quickView {
		padding: 7px 8px 2px 8px;
	}
	.quickView {
		&:hover {
			background: $icon-label;
			border-radius: 50px;
		}
	}
	.whiteButton {
		background-color: $white;
		color: $white-button-text;
		text-transform: uppercase;
		border: 1px solid $White105;
		font-size: 12px;
		margin-left: 8px;
		&:hover {
			background-color: $white;
			color: $black;
		}
	}
}

.updateButton {
	background-color: $white;
	color: $white-button-text;
	text-transform: uppercase;
	border: 1px solid $White105;
	font-size: 12px;
	&:hover {
		background-color: $white;
		color: $black;
	}
}

.customHeaderDownload {
	float: right;
	text-transform: uppercase;
	margin-top: -80px;
	background-color: $Blue400;
	border-radius: 6px;
	font-size: 13px;
	line-height: 33px;
	margin-right: 39px;
	&:hover {
		background-color: $Blue400;
		color: $white;
		cursor: pointer;
	}
	.customIcon {
		cursor: pointer;
		color: $white;
	}
}
.modalCards {
	height: 100vh;
	padding: 12px;
	.downloadPdfIcon {
		cursor: pointer;
		margin-bottom: -4px;
	}
}
.pageStyle {
	float: right;
	margin-top: -47px;
	margin-right: 85px;
}
.selectedApplications {
	margin-right: 15px;
	font-family: Lato;
	font-style: italic;
	font-weight: normal;
	font-size: 14px;
	line-height: 14px;
	align-items: center;
	margin-top: 10px;
}
.inputMaxStyle {
	border-radius: 3px;
	border: 1px solid red !important;
	width: 55px;
	input:focus {
		border: none;
		box-shadow: 0 0 0 1px rgba(255, 0, 0, 1);
	}
	input:hover {
		border-color: none;
	}
}
.loader {
	font-size: 16px;
	line-height: 20px;
	color: black;
	text-transform: uppercase;
}
.imageClass {
	vertical-align: unset;
}
.actionBtn {
	background: url(../../../assets/images/upgrad/edit.svg) no-repeat;
	width: 46px;
	height: 40px;
	cursor: pointer;
}
.actionButtonDisabled {
	cursor: none;
	opacity: 0.5;
}
