@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.secondaryHeaderOuter {
	padding: 16px 24px;
	background-color: $white;
	width: 100%;
	min-width: $max-app-width;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
	.secondaryHeaderInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			font-weight: $font-bold;
			font-size: 18px;
			line-height: 22px;
			color: $page-title;
		}
		.cBtn {
			background-color: $Blue400;
			color: $white;
			text-transform: uppercase;
			&:hover {
				background-color: $btn-primary-hover;
			}
		}
	}
}

.searchBar {
	position: relative;
	width: 280px;
	height: 32px;
	background-color: $white;
	margin-left: auto;
	margin-right: 30px;
	.searchIcon {
		position: absolute;
		left: 7px;
		top: 7px;
		path {
			fill: #bebebe;
		}
	}
	.search {
		width: 100%;
		height: 32px;
		line-height: 32px;
		input {
			margin-bottom: 0px;
			height: 32px;
			line-height: 54px;
			font-size: 14px;
			text-align: left;
			font-weight: $font-semi-bold;
			color: #90a0b7;
			margin-top: -3px;
			padding-left: 32px;
			border-radius: 3px;
			border: 1px solid #dadada;
			box-shadow: none;
			&:focus {
				border-color: #2188ff;
				box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
				outline: none;
			}
		}
	}
}
.bodyOuter {
	padding: 24px;
	width: 100%;
	min-width: $max-app-width;
	.bodyInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		.selector {
			width: 30%;
		}

		.tableContainer {
			background: $white;
			border: 1px solid $Gray200;
			border-radius: 4px;
		}
	}
}

.column {
	width: 14%;
	height: 72px;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
	&.smallColumn {
		width: 10%;
	}
	&.mediumColumn {
		width: 18%;
	}
	&.largeColumn {
		width: 54%;
	}
}

.actionBtn {
	width: 46px;
	height: 40px;
	outline: none;
	cursor: pointer;
	&.editBtn{
		background: url(../../../assets/images/upgrad/edit.svg) no-repeat;
	}
	&.redirectBtn{
		background: url(../../../assets/images/upgrad/redirect.svg) no-repeat;
		margin-left: 2px;
	}
}
