
@mixin auto-generate-margins($values, $values-vertical, $values-horizontal, $prefix:"") {

    // Margin all
    @each $val in $values{

        .#{$prefix}m-#{$val}{
            margin: #{$val}px#{$scu-val-important-margins};
        }
    }

    // Margin top, bottom and vertical
    @each $val in $values-vertical {

        .#{$prefix}mt-#{$val},
        .#{$prefix}mv-#{$val}{
            margin-top: #{$val}px#{$scu-val-important-margins};
        }

        .#{$prefix}mb-#{$val},
        .#{$prefix}mv-#{$val}{
            margin-bottom: #{$val}px#{$scu-val-important-margins};
        }
    }

    // Margin left, right and horizontal
    @each $val in $values-horizontal {

        .#{$prefix}ml-#{$val},
        .#{$prefix}mh-#{$val}{
            margin-left: #{$val}px#{$scu-val-important-margins};
        }

        .#{$prefix}mr-#{$val},
        .#{$prefix}mh-#{$val}{
            margin-right: #{$val}px#{$scu-val-important-margins};
        }
    }
}

// General margin classes

    .#{$scu-val-prefix}ma{
        margin-left: auto#{$scu-val-important-margins};
        margin-right: auto#{$scu-val-important-margins};
    }

    .#{$scu-val-prefix}ml-a, .#{$scu-val-prefix}mla { margin-left: auto#{$scu-val-important-margins}; }
    .#{$scu-val-prefix}mr-a, .#{$scu-val-prefix}mra { margin-right: auto#{$scu-val-important-margins}; }

// Auto generated margin classes

@include auto-generate-margins($scu-margins, $scu-margins-vertical, $scu-margins-horizontal, $scu-val-prefix);

@if $scu-build-tablet-classes{
    @include if-device(tablet) {
        .#{$scu-val-prefix-tablet}ma{
            margin-left: auto#{$scu-val-important-margins};
            margin-right: auto#{$scu-val-important-margins};
        }

        .#{$scu-val-prefix-tablet}ml-a,
        .#{$scu-val-prefix-tablet}mla {
            margin-left: auto#{$scu-val-important-margins};
        }
        .#{$scu-val-prefix-tablet}mr-a,
        .#{$scu-val-prefix-tablet}mra {
            margin-right: auto#{$scu-val-important-margins};
        }

        @include auto-generate-margins($scu-tablet-margins, $scu-tablet-margins-vertical, $scu-tablet-margins-horizontal, $scu-val-prefix-tablet);
    }
}

@if $scu-build-phone-tablet-classes{
    @include if-device(phone-tablet) {
        .#{$scu-val-prefix-phone-tablet}ma {
            margin-left: auto#{$scu-val-important-margins};
            margin-right: auto#{$scu-val-important-margins};
        }

        .#{$scu-val-prefix-phone-tablet}ml-a,
        .#{$scu-val-prefix-phone-tablet}mla {
            margin-left: auto#{$scu-val-important-margins};
        }
        .#{$scu-val-prefix-phone-tablet}mr-a,
        .#{$scu-val-prefix-phone-tablet}mra {
            margin-right: auto#{$scu-val-important-margins};
        }

        @include auto-generate-margins($scu-phone-tablet-margins, $scu-phone-tablet-margins-vertical, $scu-phone-tablet-margins-horizontal, $scu-val-prefix-phone-tablet);
    }
}

@if $scu-build-phone-classes{
    @include if-device(phone) {
        .#{$scu-val-prefix-phone}ma{
            margin-left: auto#{$scu-val-important-margins};
            margin-right: auto#{$scu-val-important-margins};
        }

        .#{$scu-val-prefix-phone}ml-a,
        .#{$scu-val-prefix-phone}mla {
            margin-left: auto#{$scu-val-important-margins};
        }
        .#{$scu-val-prefix-phone}mr-a,
        .#{$scu-val-prefix-phone}mra {
            margin-right: auto#{$scu-val-important-margins};
        }

        @include auto-generate-margins($scu-phone-margins, $scu-phone-margins-vertical, $scu-phone-margins-horizontal, $scu-val-prefix-phone);
    }
}

