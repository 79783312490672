@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.container {
	display: flex;
	align-content: center;
	width: auto;
	height: 32px;
	color: $white;
	text-align: right;
	user-select: none;
	cursor: pointer;
	opacity: 0;

	&.active {
		opacity: 1;
	}

	.userName {
		font-size: 14px;
		line-height: 38px;
		letter-spacing: 0.19px;
		text-transform: capitalize;
		font-weight: $font-bold;
		color: rgba($white, 0.9);
	}
}
.arrowIcon {
	margin-top: 7px;
	height: 32px;
	width: 16px;
	path {
		fill: $white;
	}
}

.profileMenuWrapper {
	position: relative;
}
