@import "../../../../sass/variables/fonts";
@import "../../../../sass/variables/spaces";
@import "../../../../sass/variables/transitions";

@import "../../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.filtersRow {
	display: flex;
	justify-content: space-between;
	.selector {
		width: 25%;
	}
	.searchInputDiv {
		width: 35%;
		.searchInput {
			background-color: $white;
			height: 42px;
			padding: 10px;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.26px;
			color: $Gray104;
		}
	}
	.applyButtonDiv {
		width: 10%;
		margin-bottom: 20px;
		.whiteButton {
			width: 100%;
			height: 42px;
			background-color: $white;
			color: $white-button-text;
			text-transform: uppercase;
			border: 1px solid $White105;
			box-sizing: border-box;
			border-radius: 4px;
			font-size: 12px;
			&:hover {
				background-color: $white;
				color: $black;
			}
		}
	}
}

.column {
	height: 72px;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.w-85 {
	width: 85px;
}
.w-115 {
	width: 115px;
}
.w-130 {
	width: 130px;
}
.w-140 {
	width: 140px;
}
.w-160 {
	width: 160px;
}
.w-288 {
	width: 288px;
}

.actionBtn {
	background: url(../../../../assets/images/upgrad/edit.svg) no-repeat;
	width: 46px;
	height: 40px;
	cursor: pointer;
}

.actionBtnSecond {
	background: url(../../../../assets/images/upgrad/users.svg) no-repeat;
	width: 46px;
	height: 40px;
	margin-left: 2px;
	cursor: pointer;
}
