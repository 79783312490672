@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.secondaryHeaderOuter {
	padding: 16px 24px;
	background-color: $white;
	width: 100%;
	min-width: $max-app-width;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
	.secondaryHeaderInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		.navList {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			min-width: 180px;
			text-align: center;
			height: 100%;

			.navItem {
				font-size: 15px;
				line-height: 18px;
				font-weight: $font-bold;
				padding: 8px;
				margin-right: 8px;
				border-radius: 4px;
				color: $page-nav-item;
				transition: 200ms $easing-expressive all;

				&:hover {
					color: $black;
				}

				&.active {
					background: rgba(33, 136, 255, 0.2);
					color: $Blue400;
				}
			}
			.createAttributeButton {
				background-color: $Blue400;
				color: $white;
				margin-left: auto;
				text-transform: uppercase;
				&:hover {
					background-color: $btn-primary-hover;
				}
			}
		}
	}
}

.bodyOuter {
	padding: 24px;
	width: 100%;
	min-width: $max-app-width;
	.bodyInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		.optionsBar {
			display: flex;
			justify-content: space-between;

			.selectorRow {
				display: flex;
				.selector {
					margin-right: 20px;
					width: 280px;
				}
			}

			.searchDiv {
				width: 280px;
				.searchInput {
					background: url(../../../assets/images/upgrad/search.svg)
						no-repeat;
					background-position: 10px center;
					height: 42px;
					padding: 10px 10px 10px 40px;
					font-size: 13px;
					line-height: 16px;
					letter-spacing: 0.26px;
					background-color: $white;
					color: $Gray104;
				}
			}
		}
	}
}

.spinner {
	text-align: center;
	margin-bottom: 12px;
}
