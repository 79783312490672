@font-face {
    font-family: $font-family-lato;
    font-style: normal;
    font-weight: $font-regular;
    src: local('Lato Regular'), local('Lato-Regular'),
    url('../assets/fonts/lato/lato-v14-latin-ext_latin-regular.woff2') format('woff2'),
    url('../assets/fonts/lato/lato-v14-latin-ext_latin-regular.woff') format('woff');
}

@font-face {
    font-family: $font-family-lato;
    font-style: normal;
    font-weight: $font-semi-bold;
    src: local('Lato SemiBold'), local('Lato-SemiBold'),
    url('../assets/fonts/lato/lato-v14-latin-ext_latin-700.woff2') format('woff2'),
    url('../assets/fonts/lato/lato-v14-latin-ext_latin-700.woff') format('woff');
}

@font-face {
    font-family: $font-family-lato;
    font-style: normal;
    font-weight: $font-bold;
    src: local('Lato Bold'), local('Lato-Bold'),
    url('../assets/fonts/lato/lato-v14-latin-ext_latin-900.woff2') format('woff2'),
    url('../assets/fonts/lato/lato-v14-latin-ext_latin-900.woff') format('woff');
}
