// Default config values. Do NOT modify this file.

$scu-rem-baseline-px: 16px !default;
$scu-rem-include-px: true !default;

// ====== Features ======

$scu-build-tablet-classes: true !default;
$scu-build-phone-tablet-classes: true !default;
$scu-build-phone-classes: true !default;

// ====== Prefixes ======

$scu-prefix: "" !default;
$scu-prefix-tablet: "tab-" !default;
$scu-prefix-phone-tablet: "phn-tab-" !default;
$scu-prefix-phone: "phn-" !default;

// ====== !important ======

$scu-use-important: false !default;
$scu-paddings-use-important: $scu-use-important !default;
$scu-margins-use-important: $scu-use-important !default;

// ====== Media Devices ======

$scu-large-desktop-min-width: 1400px !default;
$scu-large-desktop-min-height: 800px !default;
$scu-desktop-min-width: 980px !default;
$scu-tablet-min-width: 768px !default;
$scu-tablet-max-width: 979px !default;
$scu-phone-max-width: 767px !default;

// ====== Paddings ======

$scu-paddings: (
	0 1 2 4 8 12 16 20 24 32 40 48 56 64 72 80 88 96 104 112 120
) !default;
$scu-paddings-vertical: $scu-paddings !default;
$scu-paddings-horizontal: $scu-paddings !default;

$scu-tablet-paddings: $scu-paddings !default;
$scu-tablet-paddings-vertical: $scu-paddings-vertical !default;
$scu-tablet-paddings-horizontal: $scu-paddings-horizontal !default;

$scu-phone-tablet-paddings: $scu-paddings !default;
$scu-phone-tablet-paddings-vertical: $scu-paddings-vertical !default;
$scu-phone-tablet-paddings-horizontal: $scu-paddings-horizontal !default;

$scu-phone-paddings: $scu-paddings !default;
$scu-phone-paddings-vertical: $scu-paddings-vertical !default;
$scu-phone-paddings-horizontal: $scu-paddings-horizontal !default;

// ====== Margins ======

$scu-margins: (
	0 1 2 4 8 12 16 20 24 32 40 48 56 64 72 80 88 96 104 112 120
) !default;
$scu-margins-vertical: $scu-margins !default;
$scu-margins-horizontal: $scu-margins !default;

$scu-tablet-margins: $scu-margins !default;
$scu-tablet-margins-vertical: $scu-margins-vertical !default;
$scu-tablet-margins-horizontal: $scu-margins-horizontal !default;

$scu-phone-tablet-margins: $scu-margins !default;
$scu-phone-tablet-margins-vertical: $scu-margins-vertical !default;
$scu-phone-tablet-margins-horizontal: $scu-margins-horizontal !default;

$scu-phone-margins: $scu-margins !default;
$scu-phone-margins-vertical: $scu-margins-vertical !default;
$scu-phone-margins-horizontal: $scu-margins-horizontal !default;

// ====== Setting default variable values based on config. Do not edit unless you know what you are doing! ======

$scu-val-important: if($scu-use-important, " !important", "");
$scu-val-important-paddings: if($scu-paddings-use-important, " !important", "");
$scu-val-important-margins: if($scu-margins-use-important, " !important", "");

$scu-val-prefix: $scu-prefix;
$scu-val-prefix-tablet: $scu-val-prefix + $scu-prefix-tablet;
$scu-val-prefix-phone-tablet: $scu-val-prefix + $scu-prefix-phone-tablet;
$scu-val-prefix-phone: $scu-val-prefix + $scu-prefix-phone;

$spacer-base: 8px;

$spacer-half: $spacer-base / 2;
$spacer-1: $spacer-base;
$spacer-2: $spacer-base * 2;
$spacer-3: $spacer-base * 3;
$spacer-4: $spacer-base * 4;
$spacer-5: $spacer-base * 5;

$max-app-width: 1220px;
