@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import "../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.secondaryHeaderOuter {
	padding: 16px 24px;
	background-color: $white;
	width: 100%;
	min-width: $max-app-width;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
	.secondaryHeaderInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		.title {
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;
			color: $page-title;
		}
	}
}
.bodyOuter {
	padding: 24px;
	width: 100%;
	min-width: $max-app-width;
	.bodyInner {
		@include more-than-width(1220px) {
			width: 1220px;
		}
		margin: 0 auto;
		.tabComponent {
			background-color: $white;
			border: 1px solid $gray300;
			border-radius: 4px;
		}
	}
}

.pageContainer {
	margin: 36px 48px;
	.pageLabel {
		font-weight: bold;
		font-size: 18px;
		line-height: 26px;
		color: $black100;
	}
	.pageLabelLight {
		font-size: 14px;
		line-height: 17px;
		color: $Gray123;
	}
	.row {
		margin: 20px 0px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.smallInput55 {
			width: 65px;
		}
		.col50 {
			width: 45%;
		}
		.col100 {
			width: 100%;
		}
		.textArea {
			height: 200px;
		}
	}

	.colFlex {
		height: 200px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.rowFlexEnd {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		.saveButton,
		.rejectButton,
		.draftButton {
			font-weight: bold;
			background-color: $Blue400;
			color: $white;
			padding: 8px 30px;
			text-transform: uppercase;
			&:hover {
				background-color: $btn-primary-hover;
			}
		}
		.rejectButton {
			background-color: $red-base;
			margin-right: 15px;
			&:hover {
				background-color: darken($color: $red-base, $amount: 10);
			}
		}
		.draftButton {
			background: $yellow-base;
			margin-right: 15px;
			&:hover {
				background-color: darken($color: $yellow-base, $amount: 10);
			}
		}
	}

	.rowFlexCenter {
		margin: 20px 0px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.previewCard {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		padding: 20px;
		display: flex;
		.left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 175px;
			padding-right: 20px;
			.top {
				min-height: 70px;
				.previewHeader {
					font-weight: bold;
					font-size: 12px;
					line-height: 15px;
					text-align: right;
					color: $Gray101;
				}
				.data {
					margin: 10px 0px;
					display: flex;
					justify-content: flex-end;
					align-items: baseline;
					font-weight: bold;
					font-size: 18px;
					color: $Gray102;
					.heavyText {
						font-weight: bold;
						font-size: 25px;
						color: $Gray103;
					}
					.message {
						text-align: right;
						width: 130px;
						font-size: 12px;
						line-height: 16px;
					}
				}
			}
			.bottom {
				.refreshDiv {
					text-align: right;
					cursor: pointer;
					.refresh {
						font-weight: bold;
						font-size: 10px;
						line-height: 12px;
						letter-spacing: 0.01em;
						color: $green-base;
						margin-left: 5px;
					}
				}
			}
		}
		.right {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-left: 20px;
			min-width: 175px;
			border-left: 1px solid $gray300;
			.top {
				min-height: 70px;
				.cardRow {
					display: flex;
					justify-content: space-between;
					margin-bottom: 10px;
					font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.01em;
					color: $Gray103;
					.text {
						font-weight: bold;
					}
					.number {
						text-align: right;
						color: $gray-dark;
						cursor: pointer;
					}
				}
			}
			.bottom {
				display: flex;
				.showMoreDiv {
					cursor: pointer;
					.showMore {
						font-weight: bold;
						font-size: 10px;
						line-height: 12px;
						letter-spacing: 0.01em;
						color: $Blue400;
						margin-right: 5px;
					}
					.downArrow {
						transform: rotate(0deg);
						transition: transform 0.25s linear;
					}
					.upArrow {
						transform: rotate(180deg);
						transition: transform 0.25s linear;
					}
				}
			}
		}
	}
}

.reviewCommentSection {
	max-width: 1220px;
	margin: 20px auto 0 auto;
	height: 140px;
	background-color: $white;
	padding: 15px 45px;
	background: #fdf8e0;
	border: 1px solid #ffea67;
	border-radius: 4px;
	h4 {
		font-size: 18px;
		color: $Gray106;
		font-weight: 600;
	}
	div {
		max-height: 70px;
		overflow-y: scroll;
	}
}
.label {
	font-weight: 600;
	font-size: 12px;
	line-height: 24px;
	color: $Gray106;
	display: block;
}

.radioLabel {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.26px;
	color: $Gray104;
}

.inactive {
	opacity: 0.5;
}

.whiteButton {
	background-color: $white;
	color: $white-button-text;
	text-transform: uppercase;
	border: 1px solid $White105;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 12px;
	&:hover {
		background-color: $white;
		color: $black;
	}
}

.errorText {
	color: $red-base;
}

.errorBox {
	width: 100%;
}

.spinnerDiv {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.labelOnlyAdditionalProps {
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: $label;
}
.labelBasicProps {
	font-size: 12px;
	line-height: 24px;
}
.labelLight {
	color: $label-light;
}
.labelFlex30 {
	width: 30%;
}
.labelMB {
	margin-bottom: 10px;
}
.unitsLabel {
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: $label-units;
}
.charCount {
	font-weight: 600;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #adb1b3;
}
.hasError {
	color: #eb5055;
}
.spinnerText {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.26px;
	color: #c4c4c4;
}

.disabled {
	opacity: 0.4;
}

.addNewCompany {
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
	cursor: pointer;
}
