// Global Font Variables
$global-font-size: 16px;
$global-line-height: 1.3;
$font-family-poppins: 'Poppins';
$font-family-lato: 'lato';

// $font-family: $font-family-poppins;
$font-family: $font-family-lato;

// Font Weights
$font-regular: 400;
$font-semi-bold: 600;
$font-bold: 700;

$h00-size-mobile: 40px;
$h0-size-mobile: 32px;
$h1-size-mobile: 26px;
$h2-size-mobile: 22px;
$h3-size-mobile: 18px;

$h00-size: 48px;
$h0-size: 40px;
$h1-size: 32px;
$h2-size: 24px;
$h3-size: 20px;
$h4-size: 16px;
$h5-size: 14px;
$h6-size: 12px;
