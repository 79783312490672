.box-shadow {
    box-shadow: 0px 2px 4px rgba($black, 0.1);
    &-small {
        box-shadow: 0px 1px 2px rgba($black, 0.07);
    }
    &-medium {
        box-shadow: 0px 12px 25px rgba($black, 0.2);
    }
    &-large {
        box-shadow: 0px 12px 25px rgba($black, 0.2);
    }
    &-none {
        box-shadow: none;
    }
}
