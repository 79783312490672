@import "../../../../sass/variables/fonts";
@import "../../../../sass/variables/spaces";
@import "../../../../sass/variables/transitions";

@import "../../../../sass/helpers/breakpoints";


$transparent: transparent;
$white: #fff;
$black: #000;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #eb5055;
$yellow-base: #FAAD14;
$purple-base: #633ea4;
$black100: #191919;
$Gray123: #aaaaaa;
$Gray101: #79828b;
$Gray102: #c4c4c4;
$Gray103: #4a4a4a;
$Gray104: #4C4C56;
$Gray105: #70758f;
$Gray106: #adb1b3;
$Gray107: #6f748f;
$White101: #f6f6f7;
$White102: #e7e8ea;
$White103: #e5e5e5;
$White104: #e0e0e0;
$White105: #d8d8d8;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$gray-badge: #fafafa;
$gray-path: #bebebe;
$gray300: #D1D5DA;
$Gray200: #E1E4E8;
$Gray700: #444D56;
$Gray800: #5F6368;
$Blue400: #2188FF;
$gold: #FAAD14;
$orange: #fa8c16;
$indigo: #4B0082;
$green: #2FCB53;
$table-column-name: #BFC5D2;
$table-pagination-item-active: #1890ff;
$cancel-button-text: #5C5B5B;
$white-button-text: #24292E;
$page-title: #8C8C8C;
$page-nav-item: #D5D5D5;
$btn-primary-hover: #1e74d6;
$page-background-gray: #f4f4f4;
$login-page-box-header: #a1a6ad;
$border-dotted: #D9D9D9;
$content-description: #959DA5;
$label: #979797;
$label-light: #CACACA;
$label-units: #CECECE;
$label-breadcrumb: #4c4c56;
$search-input: #dadada;
$input-text: #90a0b7;
$clear-text: #bababa;
$filter-input: #dcdcdc;
$detail-label: #7a7a7a;
$name-label: #333333;
$card-label: #ededed;
$icon-label: #C8E1FF;
.tabsContainer {
	display: flex;
	justify-content: space-around;
	background-color: $white;
	margin-bottom: 20px;
	border-radius: 6px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
	color: $page-nav-item;
	font-size: 15px;
	line-height: 18px;
	font-weight: $font-bold;
	& > div {
		display: flex;
		justify-content: center;
		flex-basis: 25%;
		margin: 20px 0px;
		transition: 300ms $easing-expressive all;
		&:not(:last-child){
			border-right: 1px solid $black100;
		}
	}
	.inactive {
		&:hover{
			color: $black100;
			cursor: pointer;
		}
	}
	.active {
		color: $purple-base;
		font-weight: bold;
		cursor: pointer;
	}
}

.form {
	display: flex;
	flex-direction: column;
	background-color: $white;
	border-radius: 6px;
	padding: 40px 80px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
	.top {
		width: 100%;
		overflow: auto;
	}
	.bottom {
		min-height: 500px;
		padding-top: 50px;
		border-top: 1px solid $Gray102;
		.previewContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border: 1px solid $Gray102;
			border-radius: 4px;
			.iFrameWrapper {
				height: 100%;
				width: 100%;
				iframe {
					width: 100%;
					height: 100%;
					height: 480px;
				}
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: $purple-base;
				font-weight: 600;
				text-transform: uppercase;
				border-bottom: 1px solid $Gray102;
				padding: 20px 30px;
				.title {
					font-size: 16px;
				}
				.refreshButton {
					height: 30px;
					font-size: 12px;
					background-color: $purple-base;
					color: $white;
					border-radius: 4px;
					padding: 4px 10px;
					cursor: pointer;
					font-weight: 600;
					line-height: 22px;
					&:hover {
						background-color: lighten($color: $purple-base, $amount: 10);
					}
				}
			}
			.footer {
				display: flex;
				justify-content: space-around;
				color: $page-nav-item;
				font-size: 15px;
				line-height: 18px;
				font-weight: $font-bold;
				border-top: 1px solid $Gray102;
				& > div {
					display: flex;
					justify-content: center;
					flex-basis: 33%;
					margin: 20px 0px;
					transition: 300ms $easing-expressive all;
					&:not(:last-child){
						border-right: 1px solid $black100;
					}
				}
				.inactive {
					&:hover{
						color: $black100;
						cursor: pointer;
					}
				}
				.active {
					color: $purple-base;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
}

.imageUpload {
	display: flex;
	align-items: center;
	margin-top: 5px;
	position: relative;
	.previewButton {
		font-size: 14px;
		line-height: 24px;
		margin-left: 10px;
		cursor: pointer;
		& :first-child{
			margin-right: 4px;
		}
	}
	.recommendedSize {
		position: absolute;
		left: 124px;
		bottom: 5px;
	}
}
.eventInfo {
	margin: 20px 0;
}

.speakersContainer {
	.speakerInfo {
		display: flex;
		border-bottom: 1px solid $gray300;
		.left {
			flex-basis: 20%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 20px 40px 0 0;

			.top {
				display: flex;
				justify-content: space-between;
				font-size: 18px;
				color: $label;
				.recommendedSize {
					font-size: 14px;
				}
				.errorText {
					font-size: 14px;
					margin-top: 12px;
				}
			}
			.deleteButton {
				margin-bottom: 40px;
				width: fit-content;
				cursor: pointer;
				color: $Gray106;
				& > span{
					margin-left: 5px;
				}
				&:hover {
					color: $red-base;
				}
			}
		}
		.right {
			flex-grow: 1;
		}
	}
	.addButtonContainer {
		display: flex;
		justify-content: center;
		margin: 20px 0;
		.addButton {
			border: 1px solid $Gray106;
			border-radius: 4px;
			padding: 4px 12px;
			cursor: pointer;
			color: $Gray106;
			& > span {
				margin-left: 5px;
			}
			&:hover {
				color: $purple-base;
			}
		}
	}
}

.resourcesContainer {
	.resource {
		display: flex;
		border-bottom: 1px solid $gray300;
		.left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 20px 20px 0 0;
			font-size: 18px;
			color: $label;
			.deleteButton {
				margin-bottom: 40px;
				width: fit-content;
				cursor: pointer;
				color: $Gray106;
				& > span{
					margin-left: 5px;
				}
				&:hover {
					color: $red-base;
				}
			}
		}
		.right {
			flex-grow: 1;
		}
	}
	.addButtonContainer {
		display: flex;
		justify-content: center;
		margin: 20px 0;
		.addButton {
			border: 1px solid $Gray106;
			border-radius: 4px;
			padding: 4px 12px;
			cursor: pointer;
			color: $Gray106;
			& > span {
				margin-left: 5px;
			}
			&:hover {
				color: $purple-base;
			}
		}
	}
}

.row {
	margin: 20px 0px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.smallInput55 {
		width: 65px;
	}
	.col50 {
		width: 45%;
	}
	.col80 {
		width: 80%;
	}
	.col100 {
		width: 100%;
	}
	.textArea {
		height: 200px;
	}
}

.datePicker {
	width: 100% !important;
}

.datePickerError {
	& :first-child {
		border-color: $red-base;
	}
}

:global {
	.ant-calendar-picker-input.ant-input {
		height: 42px;
		& > .ant-calendar-range-picker-separator {
			vertical-align: unset;
		}
	}
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-0{
	margin-bottom: 0px;
}

.ml-12 {
	margin-left: 12px;
}

.labelBasicProps {
	font-size: 12px;
	line-height: 24px;
}
.labelOnlyAdditionalProps {
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: $label;
}
.charCount {
	font-weight: 600;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: $Gray106;
}

.hasError {
	color: $red-base
}

